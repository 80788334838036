var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { RecentDashboards } from './RecentDashboards';
import PageLayout from '../layout/PageLayout';
import { getMetabaseUserRecentDashboards } from '../../api/mbe';
import { LinearProgress, Box } from '@mui/material';
import { get, isNull } from 'lodash';
var Home = function (_a) {
    var attributes = _a.attributes, navigate = _a.navigate, fetchAuthSession = _a.fetchAuthSession, location = _a.location, STUDIES = _a.STUDIES, DASHBOARDS = _a.DASHBOARDS, getAccessibleDashboards = _a.getAccessibleDashboards, clientLogo = _a.clientLogo, clientName = _a.clientName, envName = _a.envName;
    var _b = useState(null), recentDashboards = _b[0], setRecentDashboards = _b[1];
    var _c = useState(true), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(0), progress = _d[0], setProgress = _d[1];
    var userName = get(attributes, 'name', '');
    var fetchWithTimeout = function (timeout, fetchFunc) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2, Promise.race([
                    fetchFunc(),
                    new Promise(function (resolve) { return setTimeout(function () { return resolve([]); }, timeout); })
                ])];
        });
    }); };
    useEffect(function () {
        var fetchDashboards = function () { return __awaiter(void 0, void 0, void 0, function () {
            var dashboards;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, fetchWithTimeout(7000, function () {
                            return getMetabaseUserRecentDashboards((attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', DASHBOARDS, fetchAuthSession, envName);
                        })];
                    case 1:
                        dashboards = _a.sent();
                        setRecentDashboards(dashboards || []);
                        setIsLoading(false);
                        return [2];
                }
            });
        }); };
        fetchDashboards();
    }, []);
    useEffect(function () {
        if (isLoading) {
            var timer_1 = setInterval(function () {
                setProgress(function (oldProgress) {
                    if (oldProgress === 100) {
                        return 0;
                    }
                    var diff = Math.random() * 10;
                    return Math.min(oldProgress + diff, 100);
                });
            }, 500);
            return function () {
                clearInterval(timer_1);
            };
        }
    }, [isLoading]);
    return !isLoading && !isNull(recentDashboards) ? (React.createElement(PageLayout, { attributes: attributes, fetchAuthSession: fetchAuthSession, navigate: navigate, location: location, STUDIES: STUDIES, DASHBOARDS: DASHBOARDS, getAccessibleDashboards: getAccessibleDashboards, clientLogo: clientLogo, clientName: clientName, envName: envName },
        React.createElement(RecentDashboards, { recentDashboards: recentDashboards, userName: userName, DASHBOARDS: DASHBOARDS }))) : (React.createElement(Box, { sx: { width: '100%' } },
        React.createElement(LinearProgress, { variant: "determinate", value: progress })));
};
export default Home;
