import { useParams, useSearchParams } from 'react-router-dom';
import { useUserAttributes } from 'src/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { STUDIES, DASHBOARDS, SPECIAL_DASHBOARDS } from 'src/utils/consts';
import { UserAttributes } from 'src/utils/types';
import { getAccessibleDashboards } from 'src/utils/dashboard_utils';
import { DashboardPage } from '@miracle-corporation/common-components';
import { fetchAuthSession } from 'src/api/user';

const Dashboard = () => {
	const { id } = useParams<{ id: string }>();
	const { attributes } = useUserAttributes();
	const navigate = useNavigate();
	const location = useLocation();
	const searchParams = useSearchParams();
	const dashboardName = id as keyof typeof DASHBOARDS;
	const dashboardId = DASHBOARDS[dashboardName].dashboard_id;
	const [ searchParamsObj ] = searchParams;

	// Retrieve tab_id and card_id from query parameters
	const tab_id = searchParamsObj.get("tab_id");
	const card_id = searchParamsObj.get("card_id");

	const tabId = tab_id ? parseInt(tab_id) : null;
	const cardId = card_id ? parseInt(card_id) : null;

	return <DashboardPage
		clientLogo={process.env.REACT_APP_CLIENT_LOGO || ''}
		clientName={process.env.REACT_APP_CLIENT_NAME || ''}
		dashboardId={dashboardId}
		tabId={tabId}
		cardId={cardId}
		envName={process.env.REACT_APP_ENVIRONMENT || ''}
		attributes={attributes as UserAttributes} 
		DASHBOARDS={DASHBOARDS} 
		SPECIAL_DASHBOARDS={SPECIAL_DASHBOARDS}
		navigate={navigate} 
		location={location} 
		STUDIES={STUDIES} 
		getAccessibleDashboards={getAccessibleDashboards}
		fetchAuthSession={fetchAuthSession}
	/>
}

export default Dashboard;