var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import Header from './Header';
import CollapsibleSidebar from './CollapsibleSidebar';
import { Box } from '@mui/material';
var sidebarCollapsedWidth = '56px';
var sidebarExpandedWidth = '240px';
var sidebarContainerStyle = {
    transition: 'width 0.3s ease',
    overflowX: 'hidden',
};
var sidebarBorderStyle = {
    borderRight: '1px solid #e0e0e0',
};
var headerBorderStyle = {
    width: '100%',
    borderBottom: '1px solid #e0e0e0',
    background: 'white',
    height: '50px',
};
var PageLayout = function (_a) {
    var children = _a.children, attributes = _a.attributes, navigate = _a.navigate, location = _a.location, STUDIES = _a.STUDIES, DASHBOARDS = _a.DASHBOARDS, getAccessibleDashboards = _a.getAccessibleDashboards, clientLogo = _a.clientLogo, clientName = _a.clientName, envName = _a.envName, fetchAuthSession = _a.fetchAuthSession;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    return (React.createElement(Box, { display: "flex", justifyContent: "space-between", flexDirection: "column", height: "100vh" },
        React.createElement(Box, { position: "fixed", top: 0, left: 0, zIndex: 1100, sx: headerBorderStyle },
            React.createElement(Header, { attributes: attributes, clientLogo: clientLogo, clientName: clientName })),
        React.createElement(Box, { display: "flex", flexGrow: 1, mt: "50px" },
            " ",
            React.createElement(Box, { width: sidebarCollapsedWidth, height: "100%", position: "fixed", paddingBottom: "50px", sx: __assign(__assign({}, sidebarContainerStyle), sidebarBorderStyle), onMouseEnter: function (e) { setOpen(true); e.currentTarget.style.width = sidebarExpandedWidth; }, onMouseLeave: function (e) { setOpen(false); e.currentTarget.style.width = sidebarCollapsedWidth; } },
                React.createElement(CollapsibleSidebar, { clientName: clientName, envName: envName, open: open, setOpen: setOpen, attributes: attributes, navigate: navigate, location: location, STUDIES: STUDIES, DASHBOARDS: DASHBOARDS, getAccessibleDashboards: getAccessibleDashboards, fetchAuthSession: fetchAuthSession })),
            React.createElement(Box, { component: "main", flexGrow: 1, marginLeft: open ? sidebarExpandedWidth : sidebarCollapsedWidth, sx: {
                    overflowY: 'auto',
                    height: 'calc(100vh - 50px)',
                    transition: 'margin-left 0.3s ease'
                } }, children))));
};
export default PageLayout;
