var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from "react";
import { TextField, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Menu, Typography, CircularProgress } from "@mui/material";
import { Add as AddIcon, Search as SearchIcon, Close as CloseIcon, MoreVert as MoreVertIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { changeRole, addUser, updateUser, deleteUser, getAllUsers } from "../../api/mbe";
import { capitalize, get } from 'lodash';
import { logUserAction } from '../../api/audit';
import { isEmpty } from 'lodash';
export default function TeamsTable(_a) {
    var _this = this;
    var attributes = _a.attributes, clientName = _a.clientName, envName = _a.envName, fetchAuthSession = _a.fetchAuthSession, successToast = _a.successToast, errorToast = _a.errorToast, ROLES = _a.ROLES;
    var _b = useState(''), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = useState('all-users'), selectedRole = _c[0], setSelectedRole = _c[1];
    var _d = useState([]), users = _d[0], setUsers = _d[1];
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var _f = useState(null), anchorEl = _f[0], setAnchorEl = _f[1];
    var _g = useState(null), selectedUser = _g[0], setSelectedUser = _g[1];
    var _h = useState(false), deleteConfirmOpen = _h[0], setDeleteConfirmOpen = _h[1];
    var _j = useState(false), roleChangeConfirmOpen = _j[0], setRoleChangeConfirmOpen = _j[1];
    var _k = useState(null), pendingRoleChange = _k[0], setPendingRoleChange = _k[1];
    var _l = useState(false), editDialogOpen = _l[0], setEditDialogOpen = _l[1];
    var _m = useState(null), editUser = _m[0], setEditUser = _m[1];
    var _o = useState(false), emailError = _o[0], setEmailError = _o[1];
    var _p = useState(false), loading = _p[0], setLoading = _p[1];
    var _q = useState({ firstname: '', lastname: '', email: '', role: '', title: '' }), newUser = _q[0], setNewUser = _q[1];
    var handleSearchChange = function (event) {
        setSearchTerm(event.target.value);
    };
    var handleRoleChangeRequest = function (event, email) {
        var updatedRole = event.target.value;
        setPendingRoleChange({ email: email, role: updatedRole });
        setRoleChangeConfirmOpen(true);
    };
    var confirmRoleChange = function () { return __awaiter(_this, void 0, void 0, function () {
        var originalUser, originalName, originalRole, userResp, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pendingRoleChange) return [3, 7];
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    originalUser = users.find(function (user) { return user.email === pendingRoleChange.email; });
                    if (!originalUser) {
                        throw new Error("Original user not found");
                    }
                    originalName = originalUser === null || originalUser === void 0 ? void 0 : originalUser.name;
                    originalRole = originalUser === null || originalUser === void 0 ? void 0 : originalUser.role;
                    return [4, changeRole(pendingRoleChange.email, pendingRoleChange.role, clientName, envName, fetchAuthSession)];
                case 2:
                    userResp = _a.sent();
                    setUsers(userResp);
                    successToast('User role updated successfully!');
                    if (!(envName === 'main')) return [3, 4];
                    return [4, logUserAction(clientName, (attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', 'USER_ROLE_UPDATED', "".concat(originalName, " Updated. Changes: role: ").concat(originalRole, " \u2192 ").concat(pendingRoleChange.role), 'USER_MANAGEMENT', { userEmail: pendingRoleChange.email, role: pendingRoleChange.role })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3, 7];
                case 5:
                    error_1 = _a.sent();
                    console.error("There was an error updating the user's role!", error_1);
                    errorToast('There was an error updating the user\'s role!');
                    return [3, 7];
                case 6:
                    setLoading(false);
                    setRoleChangeConfirmOpen(false);
                    setPendingRoleChange(null);
                    return [7];
                case 7: return [2];
            }
        });
    }); };
    var handleClickOpen = function () {
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
        setNewUser({ firstname: '', lastname: '', email: '', role: '', title: '' });
    };
    var handleAddUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var userResp, error_2, errMessage;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 6]);
                    setLoading(true);
                    return [4, addUser(newUser, clientName, envName, (attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', fetchAuthSession)];
                case 1:
                    userResp = _a.sent();
                    setOpen(false);
                    setUsers(userResp);
                    setNewUser({ firstname: '', lastname: '', email: '', role: '', title: '' });
                    successToast('User created successfully!');
                    if (!(envName === 'main')) return [3, 3];
                    return [4, logUserAction(clientName, (attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', 'USER_CREATED', "New user added: ".concat(newUser.email), 'USER_MANAGEMENT', { newUserEmail: newUser.email, role: newUser.role, title: newUser.title })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [3, 6];
                case 4:
                    error_2 = _a.sent();
                    console.error("There was an error creating the user!", error_2);
                    errMessage = '';
                    if (get(error_2, 'response.data.detail') == 'Username already exists.') {
                        errMessage = 'Username already exists.';
                    }
                    else {
                        errMessage = "There was an error creating the user!";
                    }
                    errorToast(errMessage);
                    setOpen(false);
                    setNewUser({ firstname: '', lastname: '', email: '', role: '', title: '' });
                    return [3, 6];
                case 5:
                    setLoading(false);
                    return [7];
                case 6: return [2];
            }
        });
    }); };
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var validateEmailFormat = function (e) {
        var email = e.target.value;
        if (!emailRegex.test(email)) {
            setEmailError(true);
        }
        else {
            setEmailError(false);
        }
    };
    var isFormValid = function () {
        return (newUser.firstname &&
            newUser.lastname &&
            newUser.email &&
            newUser.role &&
            !emailError);
    };
    var handleMenuClick = function (event, user) {
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    };
    var handleMenuClose = function () {
        setAnchorEl(null);
    };
    var handleEditUserClick = function (user) {
        setEditUser(user);
        setEditDialogOpen(true);
    };
    var handleEditUser = function () {
        if (selectedUser) {
            handleEditUserClick(selectedUser);
        }
        handleMenuClose();
    };
    var handleUpdateUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var originalUser, userResp, updates, changesSummary, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!editUser) return [3, 7];
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    originalUser = users.find(function (user) { return user.email === editUser.email; });
                    if (!originalUser) {
                        throw new Error("Original user not found");
                    }
                    return [4, updateUser(editUser, clientName, envName, fetchAuthSession)];
                case 2:
                    userResp = _a.sent();
                    setUsers(userResp);
                    setEditDialogOpen(false);
                    setEditUser(null);
                    successToast('User updated successfully!');
                    updates = {};
                    if (originalUser.name !== editUser.name) {
                        updates.name = { from: originalUser.name, to: editUser.name };
                    }
                    if (originalUser.role !== editUser.role) {
                        updates.role = { from: originalUser.role, to: editUser.role };
                    }
                    if (originalUser.title !== editUser.title) {
                        updates.title = { from: originalUser.title, to: editUser.title };
                    }
                    changesSummary = Object.entries(updates)
                        .map(function (_a) {
                        var field = _a[0], _b = _a[1], from = _b.from, to = _b.to;
                        return "".concat(field, ": ").concat(from, " \u2192 ").concat(to);
                    })
                        .join('; ');
                    if (!(envName === 'main')) return [3, 4];
                    return [4, logUserAction(clientName, (attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', 'USER_UPDATED', "".concat(editUser.name, " Updated. Changes: ").concat(changesSummary), 'USER_MANAGEMENT', {
                            updatedUserEmail: editUser.email,
                            updates: updates
                        })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3, 7];
                case 5:
                    error_3 = _a.sent();
                    console.error("There was an error updating the user's information!", error_3);
                    errorToast('There was an error updating the user\'s information!');
                    setEditDialogOpen(false);
                    setEditUser(null);
                    return [3, 7];
                case 6:
                    setLoading(false);
                    setEditDialogOpen(false);
                    return [7];
                case 7: return [2];
            }
        });
    }); };
    var handleDeleteUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var userResp, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedUser) return [3, 7];
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, 6, 7]);
                    return [4, deleteUser(selectedUser, clientName, envName, (attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', fetchAuthSession)];
                case 2:
                    userResp = _a.sent();
                    setUsers(userResp);
                    successToast('User deleted successfully!');
                    if (!(envName === 'main')) return [3, 4];
                    return [4, logUserAction(clientName, (attributes === null || attributes === void 0 ? void 0 : attributes.email) || '', 'USER_DELETED', "User deleted: ".concat(selectedUser.email), 'USER_MANAGEMENT', { deletedUserEmail: selectedUser.email, deletedUserRole: selectedUser.role })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [3, 7];
                case 5:
                    error_4 = _a.sent();
                    console.error("There was an error deleting the user!", error_4);
                    errorToast('There was an error deleting the user!');
                    setOpen(false);
                    return [3, 7];
                case 6:
                    setLoading(false);
                    return [7];
                case 7:
                    setDeleteConfirmOpen(false);
                    return [2];
            }
        });
    }); };
    var handleDeleteClick = function () {
        setDeleteConfirmOpen(true);
        handleMenuClose();
    };
    var handleConfirmDeleteClose = function () {
        setDeleteConfirmOpen(false);
    };
    var _r = useState({}), openRoles = _r[0], setOpenRoles = _r[1];
    var handleRoleOpen = function (email) {
        setOpenRoles(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[email] = true, _a)));
        });
    };
    var handleRoleClose = function (email) {
        setOpenRoles(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[email] = false, _a)));
        });
    };
    useEffect(function () {
        var fetchUsers = function () { return __awaiter(_this, void 0, void 0, function () {
            var userResp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, getAllUsers(clientName, envName, fetchAuthSession)];
                    case 1:
                        userResp = _a.sent();
                        if (searchTerm) {
                            userResp = userResp.filter(function (user) {
                                return user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                    user.email.toLowerCase().includes(searchTerm.toLowerCase());
                            });
                        }
                        setUsers(userResp);
                        return [2];
                }
            });
        }); };
        fetchUsers();
    }, [selectedRole, searchTerm, open, editDialogOpen, deleteConfirmOpen, roleChangeConfirmOpen]);
    return (isEmpty(users) ? React.createElement(CircularProgress, null) :
        React.createElement(React.Fragment, null,
            React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, spacing: 1, alignItems: "center", justifyContent: "space-between", sx: { mb: 2 } },
                    React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 3 },
                        React.createElement(TextField, { placeholder: "Search users", variant: "outlined", value: searchTerm, onChange: handleSearchChange, size: "small", fullWidth: true, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(SearchIcon, null))),
                            }, sx: {
                                backgroundColor: 'white',
                            } })),
                    React.createElement(Grid, { item: true, xs: 12, sm: 4, md: 2 },
                        React.createElement(FormControl, { size: "small", fullWidth: true },
                            React.createElement(Select, { value: selectedRole, onChange: function (event) { return setSelectedRole(event.target.value); }, displayEmpty: true, sx: {
                                    backgroundColor: 'white',
                                    '& .MuiSelect-select': {
                                        padding: '8px 32px 8px 8px',
                                    },
                                } },
                                React.createElement(MenuItem, { value: "all-users" },
                                    "All Users (",
                                    users.length,
                                    ")"),
                                Object.values(ROLES).map(function (role) { return (React.createElement(MenuItem, { value: role },
                                    capitalize(role),
                                    " (",
                                    users.filter(function (user) { return user.role === role; }).length,
                                    ")")); })))),
                    React.createElement(Grid, { item: true, xs: 12, sm: 2, md: 2, sx: { display: 'flex', justifyContent: 'flex-end' } },
                        React.createElement(Button, { variant: "contained", color: "primary", startIcon: React.createElement(AddIcon, null), onClick: handleClickOpen, sx: {
                                textTransform: 'none',
                                whiteSpace: 'nowrap',
                                padding: '6px 12px',
                                fontSize: '0.875rem',
                            } }, "Add User"))),
                React.createElement(Dialog, { open: open, onClose: handleClose, maxWidth: "sm", fullWidth: true },
                    React.createElement(DialogTitle, null,
                        "Add person",
                        React.createElement(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: function (theme) { return theme.palette.grey[500]; },
                            } },
                            React.createElement(CloseIcon, null))),
                    React.createElement(DialogContent, null,
                        React.createElement(TextField, { autoFocus: true, margin: "dense", id: "firstname", label: "First Name", type: "text", fullWidth: true, variant: "outlined", value: newUser.firstname, onChange: function (e) { return setNewUser(__assign(__assign({}, newUser), { firstname: e.target.value })); }, required: true }),
                        React.createElement(TextField, { margin: "dense", id: "lastname", label: "Last Name", type: "text", fullWidth: true, variant: "outlined", value: newUser.lastname, onChange: function (e) { return setNewUser(__assign(__assign({}, newUser), { lastname: e.target.value })); }, required: true }),
                        React.createElement(TextField, { margin: "dense", id: "email", label: "Email", type: "email", fullWidth: true, variant: "outlined", value: newUser.email, onChange: function (e) {
                                setEmailError(false);
                                setNewUser(__assign(__assign({}, newUser), { email: e.target.value }));
                            }, onBlur: validateEmailFormat, error: emailError, helperText: emailError ? "Invalid email format" : "", required: true }),
                        React.createElement(TextField, { margin: "dense", id: "title", label: "Title", type: "text", fullWidth: true, variant: "outlined", value: newUser.title, onChange: function (e) { return setNewUser(__assign(__assign({}, newUser), { title: e.target.value })); } }),
                        React.createElement(FormControl, { fullWidth: true, margin: "dense" },
                            React.createElement(InputLabel, { id: "group-select-label", required: true }, "Role"),
                            React.createElement(Select, { labelId: "group-select-label", id: "group-select", label: "Role", value: newUser.role, onChange: function (e) { return setNewUser(__assign(__assign({}, newUser), { role: e.target.value })); }, required: true }, Object.values(ROLES).map(function (role) { return (React.createElement(MenuItem, { value: role }, capitalize(role))); })))),
                    React.createElement(DialogActions, null,
                        React.createElement(Button, { onClick: handleClose }, "Cancel"),
                        React.createElement(Button, { onClick: handleAddUser, variant: "contained", color: "primary", disabled: !isFormValid() || loading },
                            loading && React.createElement(CircularProgress, { size: 20, sx: { marginRight: 1 } }),
                            "Add"))),
                React.createElement(TableContainer, { component: Paper },
                    React.createElement(Table, { sx: { minWidth: 650 }, "aria-label": "user table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { sx: { fontWeight: 'bold' } }, "NAME"),
                                React.createElement(TableCell, { sx: { fontWeight: 'bold' } }, "EMAIL"),
                                React.createElement(TableCell, { sx: { fontWeight: 'bold' } }, "ROLE"),
                                React.createElement(TableCell, null, " "))),
                        React.createElement(TableBody, null, (selectedRole === 'all-users' ? users : users.filter(function (user) { return user.role === selectedRole; })).map(function (user) { return (React.createElement(TableRow, { key: user.email },
                            React.createElement(TableCell, null, user.name),
                            React.createElement(TableCell, null, user.email),
                            React.createElement(TableCell, null,
                                React.createElement(FormControl, { sx: { m: 1, minWidth: 120 }, size: "small" },
                                    React.createElement(InputLabel, { id: "role-label-".concat(user.email) }, "Role"),
                                    React.createElement(Select, { labelId: "role-label-".concat(user.email), id: "role-select-".concat(user.email), open: openRoles[user.email] || false, onClose: function () { return handleRoleClose(user.email); }, onOpen: function () { return handleRoleOpen(user.email); }, value: user.role, label: "Role", onChange: function (event) { return handleRoleChangeRequest(event, user.email); } }, Object.values(ROLES).map(function (role) { return (React.createElement(MenuItem, { value: role }, capitalize(role))); })))),
                            React.createElement(TableCell, null,
                                React.createElement(IconButton, { onClick: function (e) { return handleMenuClick(e, user); }, "aria-label": "more" },
                                    React.createElement(MoreVertIcon, null)),
                                React.createElement(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }, transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }, PaperProps: {
                                        style: {
                                            boxShadow: 'none',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px'
                                        },
                                    } },
                                    React.createElement(MenuItem, { onClick: handleEditUser },
                                        React.createElement(EditIcon, null),
                                        React.createElement(Typography, { variant: "body1", marginLeft: 1 }, "Edit")),
                                    React.createElement(MenuItem, { onClick: handleDeleteClick },
                                        React.createElement(DeleteIcon, null),
                                        React.createElement(Typography, { variant: "body1", marginLeft: 1 }, "Delete")))))); }))))),
            React.createElement(Dialog, { open: deleteConfirmOpen, onClose: handleConfirmDeleteClose, "aria-labelledby": "confirm-delete-dialog-title", "aria-describedby": "confirm-delete-dialog-description" },
                React.createElement(DialogTitle, { id: "confirm-delete-dialog-title" }, "Confirm Delete"),
                React.createElement(DialogContent, null, "Are you sure you want to delete this user?"),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: handleConfirmDeleteClose }, "Cancel"),
                    React.createElement(Button, { onClick: handleDeleteUser, color: "primary", variant: "contained", disabled: loading },
                        loading && React.createElement(CircularProgress, { size: 20, sx: { marginRight: 1 } }),
                        "Delete"))),
            React.createElement(Dialog, { open: roleChangeConfirmOpen, onClose: function () { return setRoleChangeConfirmOpen(false); }, "aria-labelledby": "confirm-role-change-dialog-title", "aria-describedby": "confirm-role-change-dialog-description" },
                React.createElement(DialogTitle, { id: "confirm-role-change-dialog-title" }, "Confirm Role Change"),
                React.createElement(DialogContent, null,
                    "Are you sure you want to change this user's role to ", pendingRoleChange === null || pendingRoleChange === void 0 ? void 0 :
                    pendingRoleChange.role,
                    "?"),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: function () { return setRoleChangeConfirmOpen(false); } }, "Cancel"),
                    React.createElement(Button, { onClick: confirmRoleChange, color: "primary", variant: "contained", disabled: loading },
                        loading && React.createElement(CircularProgress, { size: 20, sx: { marginRight: 1 } }),
                        "Confirm"))),
            React.createElement(Dialog, { open: editDialogOpen, onClose: function () {
                    setEditDialogOpen(false);
                    setEditUser(null);
                }, maxWidth: "sm", fullWidth: true },
                React.createElement(DialogTitle, null, "Edit User"),
                React.createElement(DialogContent, null,
                    React.createElement(TextField, { autoFocus: true, margin: "dense", id: "edit-firstname", label: "First Name", type: "text", fullWidth: true, variant: "outlined", value: (editUser === null || editUser === void 0 ? void 0 : editUser.name.split(" ")[0]) || '', onChange: function (e) {
                            return setEditUser(function (prev) {
                                return prev
                                    ? __assign(__assign({}, prev), { name: "".concat(e.target.value, " ").concat(prev.name.split(" ")[1]) }) : null;
                            });
                        } }),
                    React.createElement(TextField, { margin: "dense", id: "edit-lastname", label: "Last Name", type: "text", fullWidth: true, variant: "outlined", value: (editUser === null || editUser === void 0 ? void 0 : editUser.name.split(" ")[1]) || '', onChange: function (e) {
                            return setEditUser(function (prev) {
                                return prev
                                    ? __assign(__assign({}, prev), { name: "".concat(prev.name.split(" ")[0], " ").concat(e.target.value) }) : null;
                            });
                        } }),
                    React.createElement(TextField, { margin: "dense", id: "edit-title", label: "Title", type: "text", fullWidth: true, variant: "outlined", value: (editUser === null || editUser === void 0 ? void 0 : editUser.title) || '', onChange: function (e) {
                            return setEditUser(function (prev) {
                                return prev
                                    ? __assign(__assign({}, prev), { title: e.target.value }) : null;
                            });
                        } }),
                    React.createElement(FormControl, { fullWidth: true, margin: "dense" },
                        React.createElement(InputLabel, { id: "edit-role-label" }, "Role"),
                        React.createElement(Select, { labelId: "edit-role-label", id: "edit-role-select", label: "Role", value: (editUser === null || editUser === void 0 ? void 0 : editUser.role) || '', onChange: function (e) {
                                return setEditUser(function (prev) {
                                    return prev
                                        ? __assign(__assign({}, prev), { role: e.target.value }) : null;
                                });
                            } }, Object.values(ROLES).map(function (role) { return (React.createElement(MenuItem, { value: role }, capitalize(role))); })))),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { onClick: function () {
                            setEditDialogOpen(false);
                            setEditUser(null);
                        } }, "Cancel"),
                    React.createElement(Button, { onClick: handleUpdateUser, color: "primary", variant: "contained", disabled: loading },
                        loading && React.createElement(CircularProgress, { size: 20, sx: { marginRight: 1 } }),
                        "Save Changes")))));
}
