var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useMemo } from 'react';
import { get } from 'lodash';
import { List, ListItem, ListItemIcon, ListItemText, Box, Collapse, ListItemButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BarChartIcon from '@mui/icons-material/BarChart';
import SearchBar from './SearchBar';
var listItemStyles = {
    height: '50px',
    '&.Mui-selected': {
        backgroundColor: 'rgba(107,33,168,.1)',
        '&:hover': {
            backgroundColor: 'rgba(107,33,168,.2)',
        },
    },
    '&:hover': {
        backgroundColor: 'rgba(107,33,168,.1)',
    },
};
var CollapsibleSidebar = function (_a) {
    var clientName = _a.clientName, envName = _a.envName, open = _a.open, setOpen = _a.setOpen, attributes = _a.attributes, navigate = _a.navigate, location = _a.location, STUDIES = _a.STUDIES, DASHBOARDS = _a.DASHBOARDS, getAccessibleDashboards = _a.getAccessibleDashboards, fetchAuthSession = _a.fetchAuthSession;
    var _b = useState(false), searchBarOpen = _b[0], setSearchBarOpen = _b[1];
    var _c = useState({}), subMenuItemsOpen = _c[0], setSubMenuItemsOpen = _c[1];
    var _d = useState(''), selectedPage = _d[0], setSelectedPage = _d[1];
    var _e = useState(false), textVisible = _e[0], setTextVisible = _e[1];
    useEffect(function () {
        if (open) {
            var timer_1 = setTimeout(function () { return setTextVisible(true); }, 300);
            return function () { return clearTimeout(timer_1); };
        }
        else {
            setTextVisible(false);
        }
    }, [open]);
    var accessibleDashboardKeys = useMemo(function () { return getAccessibleDashboards(get(attributes, 'role', '')); }, [attributes]);
    var accessibleDashboards = useMemo(function () { return Object.fromEntries(Object.entries(DASHBOARDS).filter(function (_a) {
        var key = _a[0], data = _a[1];
        return accessibleDashboardKeys.includes(key);
    })); }, [accessibleDashboardKeys]);
    useEffect(function () {
        var _a;
        var _b;
        var pathParts = location.pathname.split('/');
        if (pathParts.includes('dashboard')) {
            var currentDashboardKey = pathParts[3];
            if (selectedPage !== currentDashboardKey) {
                setSelectedPage(currentDashboardKey);
            }
            var currentStudy = (_b = accessibleDashboards[currentDashboardKey]) === null || _b === void 0 ? void 0 : _b.study;
            if (currentStudy) {
                setSubMenuItemsOpen((_a = {}, _a[currentStudy] = true, _a));
            }
        }
        else if (location.pathname === '/sponsor/home' && selectedPage !== 'home') {
            setSelectedPage('home');
            setSubMenuItemsOpen({});
        }
        else if (location.pathname === '/sponsor/settings' && selectedPage !== 'settings') {
            setSelectedPage('settings');
            setSubMenuItemsOpen({});
        }
        else if (selectedPage !== '') {
            setSelectedPage('');
            setSubMenuItemsOpen({});
        }
    }, [location.pathname]);
    var toggleSubMenu = function (study) {
        setSubMenuItemsOpen(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[study] = !prev[study], _a)));
        });
    };
    var handleNavigation = function (path, pageKey, study) {
        var _a;
        if (pageKey) {
            setSelectedPage(pageKey);
            setSubMenuItemsOpen((_a = {}, _a[study] = true, _a));
        }
        navigate(path);
    };
    var handleSignout = function () {
        navigate('/signout');
    };
    return (React.createElement(Box, { display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" },
        React.createElement(List, { disablePadding: true },
            React.createElement(ListItemButton, { onClick: function () { return setSearchBarOpen(!searchBarOpen); }, sx: listItemStyles },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(SearchIcon, { fontSize: "small" })),
                    textVisible && React.createElement(ListItemText, { primary: "Search" }),
                    searchBarOpen && React.createElement(SearchBar, { attributes: attributes, clientName: clientName, envName: envName, open: searchBarOpen, setOpen: setSearchBarOpen, DASHBOARDS: DASHBOARDS, fetchAuthSession: fetchAuthSession }))),
            React.createElement(ListItemButton, { onClick: function () { return handleNavigation('/sponsor/home', 'home'); }, selected: selectedPage === 'home', sx: listItemStyles },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(HomeIcon, { fontSize: "small" })),
                    textVisible && React.createElement(ListItemText, { primary: "Home" }))),
            STUDIES.map(function (study) { return (React.createElement(React.Fragment, { key: study },
                React.createElement(ListItemButton, { onClick: function () { return toggleSubMenu(study); }, sx: listItemStyles, disableRipple: true },
                    React.createElement(ListItem, { disablePadding: true },
                        React.createElement(ListItemIcon, null, subMenuItemsOpen[study] ? React.createElement(FolderOpenIcon, { fontSize: "small" }) : React.createElement(FolderIcon, { fontSize: "small" })),
                        textVisible && (React.createElement(React.Fragment, null,
                            React.createElement(ListItemText, { primary: study }),
                            React.createElement(ExpandMoreIcon, { fontSize: "small" }))))),
                React.createElement(Collapse, { in: subMenuItemsOpen[study], timeout: "auto", unmountOnExit: true },
                    React.createElement(List, { component: "div", disablePadding: true }, Object.entries(accessibleDashboards)
                        .filter(function (_a) {
                        var key = _a[0], data = _a[1];
                        return data.study === study;
                    })
                        .map(function (_a) {
                        var key = _a[0], data = _a[1];
                        return (React.createElement(ListItemButton, { key: key, sx: __assign({}, listItemStyles), disableRipple: true, selected: selectedPage === key, onClick: function () { return handleNavigation("/sponsor/dashboard/".concat(key, "/dashboard"), key, study); } },
                            React.createElement(ListItem, { disablePadding: true },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(FontAwesomeIcon, { icon: data.icon })),
                                textVisible && React.createElement(ListItemText, { primary: data.name }))));
                    }))))); })),
        React.createElement(List, { sx: { marginTop: 'auto' }, disablePadding: true },
            get(attributes, 'role', '') === 'admin' && (React.createElement(React.Fragment, null,
                React.createElement(ListItemButton, { onClick: function () { return handleNavigation('/sponsor/user_metrics', 'user_metrics'); }, selected: selectedPage === 'user_metrics', sx: listItemStyles },
                    React.createElement(ListItem, { disablePadding: true },
                        React.createElement(ListItemIcon, null,
                            React.createElement(BarChartIcon, null)),
                        textVisible && React.createElement(ListItemText, { primary: "User Metrics" }))),
                React.createElement(ListItemButton, { onClick: function () { return handleNavigation('/sponsor/settings', 'settings'); }, selected: selectedPage === 'settings', sx: listItemStyles },
                    React.createElement(ListItem, { disablePadding: true },
                        React.createElement(ListItemIcon, null,
                            React.createElement(SettingsIcon, null)),
                        textVisible && React.createElement(ListItemText, { primary: "Settings" }))))),
            React.createElement(ListItemButton, { onClick: handleSignout, sx: listItemStyles },
                React.createElement(ListItem, { disablePadding: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(LogoutIcon, null)),
                    textVisible && React.createElement(ListItemText, { primary: "Sign out" }))))));
};
export default CollapsibleSidebar;
