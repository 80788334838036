import { fetchAuthSession, fetchUserAttributes, getCurrentUser, signIn, signUp, signOut as signOutAuth, confirmSignIn, confirmSignUp } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import config from '../amplifyconfiguration.json';

Amplify.configure(config);

/**
 * Get the access token for the current user
 * @returns access token
 */
async function getAccessToken() {
	try {
	  const session = await fetchAuthSession();
	  const accessToken = session.tokens?.accessToken;
	  return accessToken;
	} catch (error) {
	  console.error('Error fetching access token:', error);
	  return null;
	}
}

/**
 * Get the access token for the current user
 * @returns access token
 */
async function getIdToken() {
	try {
	  // Fetch the current session
	  const session = await fetchAuthSession();
	  // Get the id token
	  const idToken = session.tokens?.idToken;

	  return idToken;
	} catch (error) {
	  console.error('Error fetching id token:', error);
	  return null;
	}
}

/**
 * Get the current user session 
 * @returns current user session (includes signInDetails, username, userId)
 */
const getUserSession = async () => {
    try {
      const res = await getCurrentUser();
      return res;
    } catch(e) {
      return null;
    }
}

/**
 * Get the user attributes
 * @returns user attributes (includes role, name, email)
 */  
const getAttributes = async () => {
  try {
    const attributes = await fetchUserAttributes();
    return {
      role: attributes['custom:role'], 
      name: attributes['name'],
      email: attributes['email'],
      title: attributes['custom:title']
	};
  } catch (error) {
    console.error('Error fetching role:', error);
    return null;
  }
}

/**
 * Sign out the user
 * @returns redirects back to signin page
 */
const useSignOut = () => {
    const navigate = useNavigate();
    const signOut = async () => {
        await signOutAuth();
        navigate('/');
    };
    return { signOut };
}

export { getAccessToken, getIdToken, getUserSession, getAttributes, signIn, signUp, useSignOut, confirmSignIn, confirmSignUp, fetchAuthSession };