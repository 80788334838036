import { useSignOut } from 'src/api/user';
import { Signout as SignOutPage } from '@miracle-corporation/common-components';

const SignOut = () => {
    const { signOut } = useSignOut();

    return <SignOutPage 
        signOut={signOut}
    />
}

export default SignOut;