import React from 'react';
import PageLayout from '../layout/PageLayout';
import Dashboard from './Dashboard';
var DashboardPage = function (_a) {
    var dashboardId = _a.dashboardId, cardId = _a.cardId, tabId = _a.tabId, attributes = _a.attributes, DASHBOARDS = _a.DASHBOARDS, navigate = _a.navigate, location = _a.location, STUDIES = _a.STUDIES, getAccessibleDashboards = _a.getAccessibleDashboards, clientLogo = _a.clientLogo, clientName = _a.clientName, envName = _a.envName, SPECIAL_DASHBOARDS = _a.SPECIAL_DASHBOARDS, fetchAuthSession = _a.fetchAuthSession;
    return React.createElement(PageLayout, { attributes: attributes, navigate: navigate, fetchAuthSession: fetchAuthSession, location: location, STUDIES: STUDIES, DASHBOARDS: DASHBOARDS, getAccessibleDashboards: getAccessibleDashboards, clientLogo: clientLogo, clientName: clientName, envName: envName },
        React.createElement("div", { style: { height: '100%' } },
            React.createElement(Dashboard, { dashboardId: dashboardId, cardId: cardId, tabId: tabId, attributes: attributes, clientName: clientName, envName: envName, DASHBOARDS: DASHBOARDS, SPECIAL_DASHBOARDS: SPECIAL_DASHBOARDS })));
};
export default DashboardPage;
