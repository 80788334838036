import { faCircleCheck, faTableList, faParachuteBox, faRankingStar, faChartLine, faMagnifyingGlassChart } from "@fortawesome/free-solid-svg-icons";

export const MOBILE_WIDTH = 600; // px

export const STUDY_NUMBERS = ['001', '002'];

// update study names for each client
export const STUDIES = [
	'Study 1', 'Study 2'
]

// update dashboard ids for each study
export const DASHBOARDS = {
    sandbox_main_update: { name: "Main Update", dashboard_id: 272, icon: faTableList, study: "Study 1", fullName: 'Study 1 Main Update' },
    sandbox_patient_profiles: { name: "Patient Profiles", dashboard_id: 1486, icon: faRankingStar, study: "Study 1", fullName: 'Study 1 Patient Profiles' },
    sandbox_enrollment_projection: { name: "Enrollment Projection", dashboard_id: 1387, icon: faChartLine, study: "Study 1", fullName: 'Study 1 Enrollment Projection' },
    sandbox_site_monitoring: { name: "Site Monitoring", dashboard_id: 273, icon: faMagnifyingGlassChart, study: "Study 1", fullName: 'Study 1 Site Monitoring' },

    biofusion_unified_safety_update: { name: "Unified Safety Update", dashboard_id: 438, icon: faRankingStar, study: "Study 2", fullName: 'Study 2 Unified Safety Update' },
    biofusion_data_review: { name: "Data Review", dashboard_id: 958, icon: faCircleCheck, study: "Study 2", fullName: 'Study 2 Data Review' },
    biofusion_drug_supply: { name: "Drug Supply", dashboard_id: 959, icon: faParachuteBox, study: "Study 2", fullName: 'Study 2 Drug Supply' },
    
};

// update roles for each client
export const ROLES = {
    OPERATIONS: 'operations',
    LEADERSHIP: 'leadership',
    MEDICAL: 'medical',
    ADMIN: 'admin',
};

// need to update with integration sync times for each study and data source
export const INTEGRATIONS_DASHBOARDS = {
    Medidata: {
        name: "Medidata", 
        icon: '/medidata.png',
        studies: [
            {
                name: "Study 1",
                dashboard_id: 2014,
            },
            {
                name: "Study 2",
                dashboard_id: 2014,
            }, 
            {
                name: "Study 3",
                dashboard_id: 2014,
            },
            {
                name: "Study 4",
                dashboard_id: 2014,
            }
        ]
    }
    , PPD: {
        name: "PPD", 
        icon: '/ppd.png',
        studies: [
            {
                name: "Study 1",
                dashboard_id: 2014,
            },
            {
                name: "Study 2",
                dashboard_id: 2014,
            }, 
            {
                name: "Study 3",
                dashboard_id: 2014,
            },
            {
                name: "Study 4",
                dashboard_id: 2014,
            }
        ]
    }
    , Smartsheet: {
        name: "Smartsheet", 
        icon: '/smartsheet.png',
        studies: [
            {
                name: "Study 1",
                dashboard_id: 2014,
            },
            {
                name: "Study 2",
                dashboard_id: 2014,
            }, 
            {
                name: "Study 3",
                dashboard_id: 2014,
            },
            {
                name: "Study 4",
                dashboard_id: 2014,
            }
        ]
    }

}

export const INTEGRATIONS_QUESTIONS = {
    Medidata: {
        name: "Medidata", 
        icon: '/medidata.png',
        studies: [
            {
                name: "Study 1",
                question_id: 4921,
            },
            {
                name: "Study 2",
                question_id: 4921,
            },
            {
                name: "Study 3",
                question_id: 4921,
            },
            {
                name: "Study 4",
                question_id: 4921,
            }
        ]
    },
    PPD: {
        name: "PPD", 
        icon: '/ppd.png',
        studies: [
            {
                name: "Study 1",
                question_id: 4921,
            },
            {
                name: "Study 2",
                question_id: 4921,
            }, 
            {
                name: "Study 3",
                question_id: 4921,
            },
            {
                name: "Study 4",
                question_id: 4921,
            }
        ]
    }, 
    Smartsheet: {
        name: "Smartsheet", 
        icon: '/smartsheet.png',
        studies: [
            {
                name: "Study 1",
                question_id: 4921,
            },
            {
                name: "Study 2",
                question_id: 4921,
            }, 
            {
                name: "Study 3",
                question_id: 4921,
            },
            {
                name: "Study 4",
                question_id: 4921,
            }
        ]
    }
}

export const SPECIAL_DASHBOARDS = {
  user_metrics: { name: "User Metrics", dashboard_id: 2146 },
  content_activity: { name: "Content Activity", dashboard_id: 2113 }
};