import { toast } from 'react-toastify';

export const successToast = (successMsg: string) => {
    toast.success(successMsg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        style: {
            backgroundColor: '#d4edda', // Soft green background
            color: '#155724' // Darker green text color
        }
    });
};

export const errorToast = (errorMsg: string) => {
    toast.error(errorMsg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        style: {
          backgroundColor: '#f8d7da', // Soft red background
          color: '#721c24' // Darker red text color
        }
      });
};