import { useUserAttributes } from 'src/UserContext';
import { fetchAuthSession } from 'src/api/user';
import { useNavigate, useLocation } from 'react-router-dom';
import { STUDIES, DASHBOARDS } from 'src/utils/consts';
import { getAccessibleDashboards } from 'src/utils/dashboard_utils';
import { Home as HomeComponent } from '@miracle-corporation/common-components';
import { UserAttributes } from 'src/utils/types';

const Home = () => {
  const { attributes } = useUserAttributes();
  const navigate = useNavigate();
  const location = useLocation();

  return <HomeComponent
    clientLogo={process.env.REACT_APP_CLIENT_LOGO || ''}
    clientName={process.env.REACT_APP_CLIENT_NAME || ''}
    attributes={attributes as UserAttributes} 
    fetchAuthSession={fetchAuthSession}
    navigate={navigate}
    location={location}
    STUDIES={STUDIES}
    DASHBOARDS={DASHBOARDS}
    getAccessibleDashboards={getAccessibleDashboards}
    envName={process.env.REACT_APP_ENVIRONMENT || ''}
    />
};

export default Home;