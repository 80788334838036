import { useUserAttributes } from 'src/UserContext';
import { fetchAuthSession } from 'src/api/user';
import { useNavigate, useLocation } from 'react-router-dom';
import { STUDIES, DASHBOARDS, INTEGRATIONS_DASHBOARDS, SPECIAL_DASHBOARDS, INTEGRATIONS_QUESTIONS, ROLES, STUDY_NUMBERS } from 'src/utils/consts';
import { UserAttributes } from 'src/utils/types';
import { getAccessibleDashboards } from 'src/utils/dashboard_utils';
import { Settings as SettingsPage } from '@miracle-corporation/common-components';
import { successToast, errorToast } from 'src/utils/helperFns';

const Settings = () => {
	const { attributes } = useUserAttributes();
	const navigate = useNavigate();
	const location = useLocation();

	return <SettingsPage
		attributes={attributes as UserAttributes} 
		navigate={navigate} 
		location={location} 
		STUDIES={STUDIES}
		DASHBOARDS={DASHBOARDS}
		getAccessibleDashboards={getAccessibleDashboards}
		clientLogo={process.env.REACT_APP_CLIENT_LOGO || ''}
		clientName={process.env.REACT_APP_CLIENT_NAME || ''}
    	envName={process.env.REACT_APP_ENVIRONMENT || ''}
    	fetchAuthSession={fetchAuthSession}
		INTEGRATIONS_DASHBOARDS={INTEGRATIONS_DASHBOARDS}
		INTEGRATIONS_QUESTIONS={INTEGRATIONS_QUESTIONS}
		successToast={successToast}
		errorToast={errorToast}
		SPECIAL_DASHBOARDS={SPECIAL_DASHBOARDS}
		ROLES={ROLES}
		STUDY_NUMBERS={STUDY_NUMBERS}
		/>
}

export default Settings;
