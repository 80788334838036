import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAttributes } from './api/user';
import { UserAttributes } from './utils/types';

interface UserContextType {
  attributes: UserAttributes | null;
  isLoading: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [attributes, setAttributes] = useState<UserAttributes | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const attr = await getAttributes();
        setAttributes(attr as UserAttributes);
      } catch (error) {
        console.error("Error fetching attributes:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAttributes();
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <UserContext.Provider value={{ attributes, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserAttributes = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserAttributes must be used within a UserProvider');
  }
  return context;
};