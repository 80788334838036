export var MAIN_BACKEND_URL = "https://main-backend.miracleml.com";
export var DEV_BACKEND_URL = "https://dev-backend.miracleml.com";
export var METABASE_SITE_URL = "https://visualizations.miracleml.com";
export var METABASE_AUTH_URL = "https://metabase-auth.miracleml.com";
export var MOBILE_WIDTH = 600;
export var getBackendUrl = function (envName) {
    switch (envName) {
        case 'main':
            return MAIN_BACKEND_URL;
        case 'dev':
            return DEV_BACKEND_URL;
        default:
            throw new Error("Unknown environment: ".concat(envName));
    }
};
