var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { getUserSession } from '../../api/user';
import { Container, Typography, TextField, Button, Box, Stack, } from '@mui/material';
import VerifyOtpPage from './VerifyOTP';
export var SignIn = function (_a) {
    var signIn = _a.signIn, confirmSignIn = _a.confirmSignIn, getCurrentUser = _a.getCurrentUser, clientLogo = _a.clientLogo, redirectUrl = _a.redirectUrl;
    var _b = useState(''), username = _b[0], setUsername = _b[1];
    var _c = useState(false), showOtpPage = _c[0], setShowOtpPage = _c[1];
    var _d = useState(''), error = _d[0], setError = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    useEffect(function () {
        var checkSession = function () { return __awaiter(void 0, void 0, void 0, function () {
            var user;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, getUserSession({ getCurrentUser: getCurrentUser })];
                    case 1:
                        user = _a.sent();
                        if (user) {
                            window.location.href = redirectUrl;
                        }
                        return [2];
                }
            });
        }); };
        checkSession();
    }, [redirectUrl]);
    var login = function () { return __awaiter(void 0, void 0, void 0, function () {
        var lowercaseUsername, nextStep, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    lowercaseUsername = username.toLocaleLowerCase();
                    setLoading(true);
                    return [4, signIn({
                            username: lowercaseUsername,
                            password: lowercaseUsername,
                            options: {
                                authFlowType: 'CUSTOM_WITH_SRP',
                            },
                        })];
                case 1:
                    nextStep = (_a.sent()).nextStep;
                    if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
                        setLoading(false);
                        setShowOtpPage(true);
                    }
                    else {
                        throw new Error('Something went wrong with sign in');
                    }
                    return [3, 3];
                case 2:
                    error_1 = _a.sent();
                    setLoading(false);
                    console.error('Error signing in', error_1);
                    setError('Invalid email. Please check your spelling and try again.');
                    return [3, 3];
                case 3: return [2];
            }
        });
    }); };
    if (showOtpPage) {
        return React.createElement(VerifyOtpPage, { username: username, confirmSignIn: confirmSignIn, redirectUrl: redirectUrl, clientLogo: clientLogo });
    }
    return (React.createElement(Stack, { component: "main", sx: { height: '100vh' } },
        React.createElement(Stack, { direction: "column", justifyContent: "center", alignItems: "center", spacing: 2, height: "100%" },
            React.createElement(Container, { maxWidth: "xs" },
                React.createElement(Box, { display: "flex", justifyContent: "center", mb: 3 },
                    React.createElement("img", { src: "/".concat(clientLogo), alt: "logo" })),
                React.createElement(Typography, { variant: "h5", align: "center", sx: { fontWeight: 700 } }, "Welcome to Miracle"),
                React.createElement(Typography, { variant: "body1", align: "center", sx: { color: 'text.secondary', mt: 1, mb: 3 } }, "Enter your email below and we'll send you a one-time passcode to log in password-free."),
                React.createElement(TextField, { fullWidth: true, variant: "outlined", placeholder: "you@email.com", type: "email", value: username, onChange: function (e) {
                        setError('');
                        setUsername(e.target.value);
                    }, sx: { mb: 3 } }),
                React.createElement(Button, { fullWidth: true, variant: "contained", color: "primary", onClick: login, sx: {
                        py: 1.5,
                        backgroundColor: '#1A1A1A',
                        ':hover': { backgroundColor: '#333333' },
                    }, disabled: loading }, "Sign in"),
                React.createElement(Box, { display: "flex", justifyContent: "center", mt: 2, sx: { height: '24px' } }, error && React.createElement(Typography, { variant: "body1", align: "center", sx: { color: 'red' } }, error))))));
};
export default SignIn;
