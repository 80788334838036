import { Amplify } from 'aws-amplify';
import { signIn, confirmSignIn, getCurrentUser } from 'aws-amplify/auth';
import config from '../amplifyconfiguration.json';
import { SignIn } from '@miracle-corporation/common-components';

Amplify.configure(config);

const SignInPage = () => {
    return <SignIn 
        signIn={signIn}
        confirmSignIn={confirmSignIn}
        getCurrentUser={getCurrentUser}
        clientLogo={`${process.env.REACT_APP_CLIENT_LOGO}`}
        redirectUrl="/sponsor/home"
    />
}

export default SignInPage;