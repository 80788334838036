var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getJWT } from './jwt';
import { getBackendUrl } from '../utils/consts';
import axios from 'axios';
var mapToUsers = function (apiResponse) {
    return apiResponse.users
        .filter(function (user) {
        var siteIdAttr = user.Attributes.find(function (attr) { return attr.Name === "custom:site_id"; });
        return !siteIdAttr || siteIdAttr.Value === '';
    })
        .map(function (user) {
        var emailAttr = user.Attributes.find(function (attr) { return attr.Name === "email"; });
        var nameAttr = user.Attributes.find(function (attr) { return attr.Name === "name"; });
        var roleAttr = user.Attributes.find(function (attr) { return attr.Name === "custom:role"; });
        var titleAttr = user.Attributes.find(function (attr) { return attr.Name === "custom:title"; });
        return {
            email: emailAttr ? emailAttr.Value : '',
            name: nameAttr ? nameAttr.Value : '',
            role: roleAttr ? roleAttr.Value : '',
            title: titleAttr ? titleAttr.Value : '',
        };
    });
};
export function getMetabaseUserRecentDashboards(email, DASHBOARDS, fetchAuthSession, envName) {
    return __awaiter(this, void 0, void 0, function () {
        var backendUrl, user_jwt, response, data, timeSortedData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 6, , 7]);
                    backendUrl = getBackendUrl(envName);
                    return [4, getJWT(fetchAuthSession, envName)];
                case 1:
                    user_jwt = _a.sent();
                    return [4, fetch("".concat(backendUrl, "/new_auth/recent_dashboards"), {
                            headers: {
                                "Content-Type": "application/json",
                                accept: "application/json",
                                authorization: "Bearer ".concat(user_jwt),
                            },
                            body: JSON.stringify({ email: email, filtered_dashboards: Object.values(DASHBOARDS).map(function (d) { return String(d.dashboard_id); }) }),
                            method: "POST"
                        })];
                case 2:
                    response = _a.sent();
                    if (!!response.ok) return [3, 3];
                    console.log("Error fetching recent dashboards: ".concat(response.statusText));
                    return [2, []];
                case 3: return [4, response.json()];
                case 4:
                    data = _a.sent();
                    timeSortedData = __spreadArray([], data, true).sort(function (a, b) { return new Date(b.last_seen).getTime() - new Date(a.last_seen).getTime(); });
                    return [2, timeSortedData];
                case 5: return [3, 7];
                case 6:
                    error_1 = _a.sent();
                    console.log('Failed to fetch recent dashboards!', error_1);
                    return [2, []];
                case 7: return [2];
            }
        });
    });
}
export function getAllUsers(clientName, envName, fetchAuthSession) {
    return __awaiter(this, void 0, void 0, function () {
        var backendUrl, user_jwt, response, data, users, sortedUsers, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, , 5]);
                    backendUrl = getBackendUrl(envName);
                    return [4, getJWT(fetchAuthSession, envName)];
                case 1:
                    user_jwt = _a.sent();
                    return [4, fetch("".concat(backendUrl, "/new_auth/list-users-in-pool"), {
                            headers: {
                                "Content-Type": "application/json",
                                accept: "application/json",
                                authorization: "Bearer ".concat(user_jwt)
                            },
                            body: JSON.stringify({
                                client_name: clientName,
                                env_name: envName
                            }),
                            method: "POST"
                        })];
                case 2:
                    response = _a.sent();
                    return [4, response.json()];
                case 3:
                    data = _a.sent();
                    users = mapToUsers(data);
                    sortedUsers = users.sort(function (a, b) { return a.name.localeCompare(b.name); });
                    return [2, sortedUsers];
                case 4:
                    error_2 = _a.sent();
                    console.log('Failed to fetch users', error_2);
                    return [2, []];
                case 5: return [2];
            }
        });
    });
}
export var changeRole = function (email, role, clientName, envName, fetchAuthSession) { return __awaiter(void 0, void 0, void 0, function () {
    var backendUrl, userResp, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                backendUrl = getBackendUrl(envName);
                return [4, axios.put("".concat(backendUrl, "/new_auth/update-role"), {
                        username: email,
                        role: role,
                        client_name: clientName,
                        env_name: envName
                    })];
            case 1:
                _a.sent();
                return [4, getAllUsers(clientName, envName, fetchAuthSession)];
            case 2:
                userResp = _a.sent();
                return [2, userResp];
            case 3:
                error_3 = _a.sent();
                console.error("There was an error updating the user's role!", error_3);
                throw error_3;
            case 4: return [2];
        }
    });
}); };
export var addUser = function (newUser, clientName, envName, admin_user, fetchAuthSession) { return __awaiter(void 0, void 0, void 0, function () {
    var backendUrl, response, userResp, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                backendUrl = getBackendUrl(envName);
                return [4, axios.post("".concat(backendUrl, "/new_auth/signup"), {
                        name: newUser.firstname + " " + newUser.lastname,
                        username: newUser.email,
                        role: newUser.role,
                        title: newUser.title,
                        client_name: clientName,
                        env_name: envName,
                        admin_user: admin_user
                    })];
            case 1:
                response = _a.sent();
                return [4, getAllUsers(clientName, envName, fetchAuthSession)];
            case 2:
                userResp = _a.sent();
                return [2, userResp];
            case 3:
                error_4 = _a.sent();
                console.error('There was an error creating the user!', error_4);
                throw error_4;
            case 4: return [2];
        }
    });
}); };
export var updateUser = function (editUser, clientName, envName, fetchAuthSession) { return __awaiter(void 0, void 0, void 0, function () {
    var backendUrl, userResp, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                backendUrl = getBackendUrl(envName);
                return [4, axios.put("".concat(backendUrl, "/new_auth/update-user-attributes"), {
                        username: editUser.email,
                        name: editUser.name,
                        role: editUser.role,
                        title: editUser.title,
                        client_name: clientName,
                        env_name: envName
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                        }
                    })];
            case 1:
                _a.sent();
                return [4, getAllUsers(clientName, envName, fetchAuthSession)];
            case 2:
                userResp = _a.sent();
                return [2, userResp];
            case 3:
                error_5 = _a.sent();
                console.error("There was an error updating the user's information!", error_5);
                throw error_5;
            case 4: return [2];
        }
    });
}); };
export var deleteUser = function (selectedUser, clientName, envName, admin_user, fetchAuthSession) { return __awaiter(void 0, void 0, void 0, function () {
    var backendUrl, userResp, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                backendUrl = getBackendUrl(envName);
                return [4, axios.delete("".concat(backendUrl, "/new_auth/delete-account"), {
                        params: {
                            username: selectedUser.email,
                            client_name: clientName,
                            env_name: envName,
                            admin_user: admin_user
                        }
                    })];
            case 1:
                _a.sent();
                return [4, getAllUsers(clientName, envName, fetchAuthSession)];
            case 2:
                userResp = _a.sent();
                return [2, userResp];
            case 3:
                error_6 = _a.sent();
                console.error("There was an error deleting the user!", error_6);
                throw error_6;
            case 4: return [2];
        }
    });
}); };
export var searchDashboards = function (clientName, query, envName) { return __awaiter(void 0, void 0, void 0, function () {
    var backendUrl, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                backendUrl = getBackendUrl(envName);
                return [4, axios.get("".concat(backendUrl, "/metabase_entities/search"), {
                        params: { client_name: clientName, query: query }
                    })];
            case 1:
                response = _a.sent();
                return [2, response.data];
        }
    });
}); };
