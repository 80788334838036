var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, List, ListItem, ListItemText, Typography, Chip, CircularProgress } from '@mui/material';
import { searchDashboards, getMetabaseUserRecentDashboards } from '../../api/mbe';
import { logApiUsage } from '../../api/audit';
var SearchBar = function (_a) {
    var open = _a.open, setOpen = _a.setOpen, clientName = _a.clientName, envName = _a.envName, attributes = _a.attributes, DASHBOARDS = _a.DASHBOARDS, fetchAuthSession = _a.fetchAuthSession;
    var _b = useState(''), query = _b[0], setQuery = _b[1];
    var _c = useState([]), results = _c[0], setResults = _c[1];
    var _d = useState([]), recentDashboards = _d[0], setRecentDashboards = _d[1];
    var _e = useState(query), debouncedQuery = _e[0], setDebouncedQuery = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var getDashboardKeyAndNameById = function (dashboard_id) {
        var dashboardKey = Object.keys(DASHBOARDS).find(function (key) { return DASHBOARDS[key].dashboard_id === dashboard_id; });
        if (dashboardKey) {
            return {
                key: dashboardKey,
                fullName: DASHBOARDS[dashboardKey].fullName,
            };
        }
        return undefined;
    };
    var getItemUrl = function (entity) {
        var dashboardInfo = getDashboardKeyAndNameById(entity.dashboard_id);
        var url = "/sponsor/dashboard/".concat(dashboardInfo === null || dashboardInfo === void 0 ? void 0 : dashboardInfo.key, "/dashboard");
        if (entity.type === 'Tab' && entity.tab_id) {
            url += "?tab_id=".concat(entity.tab_id);
        }
        else if (entity.type === 'Card' && entity.tab_id) {
            url += "?tab_id=".concat(entity.tab_id, "&card_id=").concat(entity.id);
        }
        return url;
    };
    var handleClose = function () { return setOpen(false); };
    useEffect(function () {
        var handler = setTimeout(function () {
            setDebouncedQuery(query);
        }, 300);
        return function () {
            clearTimeout(handler);
        };
    }, [query]);
    useEffect(function () {
        var fetchResults = function () { return __awaiter(void 0, void 0, void 0, function () {
            var results_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!debouncedQuery) return [3, 2];
                        return [4, searchDashboards(clientName, debouncedQuery, envName)];
                    case 1:
                        results_1 = _a.sent();
                        setResults(results_1);
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        }); };
        fetchResults();
    }, [debouncedQuery]);
    useEffect(function () {
        var fetchRecentDashboards = function () { return __awaiter(void 0, void 0, void 0, function () {
            var recentDashboards;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setLoading(true);
                        return [4, getMetabaseUserRecentDashboards(attributes.email, DASHBOARDS, fetchAuthSession, envName)];
                    case 1:
                        recentDashboards = _a.sent();
                        setRecentDashboards(recentDashboards);
                        setLoading(false);
                        return [2];
                }
            });
        }); };
        fetchRecentDashboards();
    }, [clientName]);
    return (React.createElement(Modal, { open: open, onClose: handleClose, sx: { marginTop: '100px' } },
        React.createElement(Box, { p: 4, width: '75%', bgcolor: "background.paper", margin: "auto", borderRadius: 4, onClick: function (e) { return e.stopPropagation(); } },
            React.createElement(TextField, { fullWidth: true, placeholder: "Search for a dashboard, card, or tab...", value: query, sx: { borderRadius: '10px' }, onChange: function (e) { return setQuery(e.target.value); } }),
            React.createElement(Typography, { variant: "body1", sx: { mt: 2, mb: 1, fontWeight: 'bold' } }, !!query ? 'Search Results' : 'Recent Dashboards'),
            loading && React.createElement(Box, { sx: { display: 'flex', justifyContent: 'center', mt: 2 } },
                React.createElement(CircularProgress, { color: "secondary" })),
            React.createElement(Box, { sx: { maxHeight: 400, overflowY: 'auto', mt: 2 } },
                React.createElement(List, null, !!query ? (results.length > 0 ? (results.map(function (entity, index) {
                    var _a;
                    var itemUrl = getItemUrl(entity);
                    var dashboardName = (_a = getDashboardKeyAndNameById(entity.dashboard_id)) === null || _a === void 0 ? void 0 : _a.fullName;
                    return (React.createElement("a", { style: { color: '#6b21a8', textDecoration: 'none' }, onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        e.preventDefault();
                                        return [4, logApiUsage(clientName, attributes.email, '/search', "User selected ".concat(entity.type, " with name: ").concat(entity.name, " and ID: ").concat(entity.id))];
                                    case 1:
                                        _a.sent();
                                        window.location.href = itemUrl;
                                        return [2];
                                }
                            });
                        }); } },
                        React.createElement(ListItem, { key: index, sx: {
                                backgroundColor: 'rgba(107,33,168,.2)',
                                '&:hover': {
                                    backgroundColor: 'rgba(107,33,168,.4)',
                                },
                                mb: 1
                            } },
                            React.createElement(ListItemText, { primary: React.createElement(Typography, { variant: "body1", component: "span" },
                                    entity.type === 'Dashboard' ? dashboardName : entity.name,
                                    React.createElement(Typography, { color: "text.secondary", component: "span" }, entity.type === 'Dashboard' ? '' : ' - ' + dashboardName),
                                    React.createElement(Chip, { label: entity.type, size: "small", sx: { marginLeft: 1 }, color: "secondary" })) }))));
                })) : React.createElement(Typography, { variant: "body1", component: "span" }, "No results found")) :
                    (recentDashboards.map(function (dashboard, index) {
                        var dashboardInfo = getDashboardKeyAndNameById(dashboard.id);
                        return (React.createElement("a", { href: "/sponsor/dashboard/".concat(dashboardInfo === null || dashboardInfo === void 0 ? void 0 : dashboardInfo.key, "/dashboard"), style: { color: '#6b21a8', textDecoration: 'none' } },
                            React.createElement(ListItem, { key: index, sx: {
                                    backgroundColor: 'rgba(107,33,168,.2)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(107,33,168,.4)',
                                    },
                                    mb: 1
                                } },
                                React.createElement(ListItemText, { primary: React.createElement(Typography, { variant: "body1", component: "span" }, dashboardInfo === null || dashboardInfo === void 0 ? void 0 : dashboardInfo.fullName) }))));
                    })))))));
};
export default SearchBar;
