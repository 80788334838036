import { useUserAttributes } from 'src/UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { STUDIES, DASHBOARDS } from 'src/utils/consts';
import { UserAttributes } from 'src/utils/types';
import { getAccessibleDashboards } from 'src/utils/dashboard_utils';
import { SPECIAL_DASHBOARDS } from 'src/utils/consts';
import { DashboardPage } from '@miracle-corporation/common-components';
import { fetchAuthSession } from 'src/api/user';

const UserMetrics = () => {
	const { attributes } = useUserAttributes();
	const navigate = useNavigate();
	const location = useLocation();

	return <DashboardPage
		fetchAuthSession={fetchAuthSession}
		clientLogo={process.env.REACT_APP_CLIENT_LOGO || ''}
		clientName={process.env.REACT_APP_CLIENT_NAME || ''}
		dashboardId={SPECIAL_DASHBOARDS.user_metrics.dashboard_id} 
		tabId={null}
		cardId={null}
		envName={process.env.REACT_APP_ENVIRONMENT || ''}
		attributes={attributes as UserAttributes} 
		DASHBOARDS={DASHBOARDS}
		SPECIAL_DASHBOARDS={SPECIAL_DASHBOARDS}
		navigate={navigate} 
		location={location} 
		STUDIES={STUDIES} 
		getAccessibleDashboards={getAccessibleDashboards}
	/>
}

export default UserMetrics;