import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserSession } from './api/user';

const AuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const user = await getUserSession();
      if (!user) {
        navigate('/'); // Redirect to sign-in page if not authenticated
      }
    };

    checkAuth();
  }, [navigate]);

  return <>{children}</>;
};

export default AuthGuard;
