import { MOBILE_WIDTH } from "../../utils/consts";
import React, { useState, useEffect } from "react";
export function MetabaseIFrame(_a) {
    var staticURL = _a.staticURL, authURL = _a.authURL;
    var _b = useState(null), iframeUrl = _b[0], setIframeUrl = _b[1];
    useEffect(function () {
        if (window.innerWidth <= MOBILE_WIDTH) {
            setIframeUrl(staticURL);
        }
        else {
            setIframeUrl(authURL);
        }
    }, [staticURL, authURL]);
    if (!iframeUrl)
        return React.createElement("div", { className: "h-full w-full flex items-center justify-center text-4xl" }, "Loading...");
    return React.createElement("iframe", { src: iframeUrl, frameBorder: "0", width: "100%", height: "100%" });
}
